/*****Hide/show top nav*****/
@media screen and (min-width: 1024px) {
  #mobile-nav, .m-req-btn {
    display: none;
  }
}
/* 1280 * 1024 Resolution */
/* 1440 * 900 Resolution */
/* 1600 * 900 Resolution */
@media screen and (max-width: 1022px) {
  .main-nav .topNavBar ul li a.mobile-req {
    display: block;
    padding: 3px 10px;
  }
  .hamburger-wrap {
    visibility: visible;
    pointer-events: visible;
    position: relative;
  }
  nav#top-nav .mobile-menu-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    position: fixed;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  nav#top-nav .mobile-menu-wrap.show {
    transform: translate(0, 0);
    height: 100%;
    top: 0;
    left: 0;
  }
  nav#top-nav .mobile-menu-wrap .nav-item .nav-link {
    font-size: 24px;
    margin-bottom: 10px;
  }
  nav#top-nav .mobile-menu-wrap .nav-item .nav-link::before {
    display: none;
  }
  nav#top-nav {
    padding: 0px;
  }
  nav#top-nav.fix-nav {
    padding: 0px;
  }
  .logo img {
    max-width: 110px;
  }
  .fix-nav .logo img {
    max-width: 100px;
  }
}
@media screen and (max-width: 1022px) {
  ul.nav-list {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .container-fluid, .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}