/*****Hide/show top nav*****/
@media screen and (min-width: 1024px){ #mobile-nav, .m-req-btn{display: none;}}
@media screen and (max-width: 1023px){}
//Screen 1366*768
@import './responsive/screen-1366';

//Screen 1280*1024
@import './responsive/screen-1280w-1024h';

//Screen 1152*864
@import './responsive/screen-1140w-1160w';

//Screen 1280*720
@import './responsive/screen-1280w-720h';

//Screen 1024*768
@import './responsive/screen-1024';

//Hamburger nav
@import './nav/hamburger';

//Global Small Screen
@import './responsive/screen-1022';

//Global Small Screen
@import './responsive/ipad';

//Global Small Screen
@import './responsive/screen-767';

//Screen 1600
@import './responsive/screen-1600';

//Screen 1600
@import './responsive/screen-1900';





